<template>
  <div class="row">
    <div class="flex md12">
      <h6 class="display-6">
        Tarifs de Base:
      </h6>
      <div class="row">
        <div class="flex md3">
          <va-input v-model.number="form.baseAdultPrice" type="number" label="Tarif Adulte (Dhs)" placeholder="Requis"/>
        </div>
        <div class="flex md3">
          <va-input v-model.number="form.baseChildrenPrice" type="number" label="Tarif Enfant (Dhs)" placeholder="Optionnel"/>
        </div>
        <div class="flex md3">
          <va-input v-model.number="form.baseInfantPrice" type="number" label="Tarif Bébé (Dhs)" placeholder="Optionnel"/>
        </div>
        <div class="flex md3"></div>
        <div class="flex md3"></div>
        <div class="flex md3">
          <va-input v-model.number="form.maxChildrenAge" type="number" label="Age maximum enfants" placeholder="Optionnel"/>
        </div>
        <div class="flex md3">
          <va-input v-model.number="form.maxInfantAge" type="number" label="Age maximum bébés" placeholder="Optionnel"/>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="flex md12">
      <h6 class="display-6">
        Dates de Départ:
      </h6>
    </div>
  </div>
  <div class="row">
    <div class="flex md4">
      <va-date-picker
        first-weekday="Monday"
        :highlight-weekend="true"
        :model-value="selectedDates"
        @click:day="toggleDate($event)"
        mode="multiple"
        show-other-months
      />
    </div>
    <va-divider vertical></va-divider>
    <div class="flex md7">
      <va-data-table :items="form.detailedDates" :columns="columns" hoverable>
        <template #cell(date)="{ value }">{{ formatDate(value) }}</template>
        <template #cell(adultPrice)="{ value, rowIndex }">
          <va-input :model-value="value" type="number" placeholder="Requis" @update:model-value="updateAdultPrice($event, rowIndex)" />
        </template>
        <template #cell(childPrice)="{ value, rowIndex }">
          <va-input :model-value="value" type="number" placeholder="Optionnel" @update:model-value="updateChildPrice($event, rowIndex)"/>
        </template>
        <template #cell(infantPrice)="{ value, rowIndex }">
          <va-input :model-value="value" type="number" placeholder="Optionnel" @update:model-value="updateInfantPrice($event, rowIndex)"/>
        </template>
      </va-data-table>
    </div>
  </div>
  <div class="row">
    <div class="flex md12">
      <h6 class="display-6">
        Suppléments:
      </h6>
    </div>
  </div>
  <div class="row">
    <div class="flex md12">
      <va-button @click="addSupplement" color="success">
        <va-icon name="add"></va-icon>
        Ajouter un Supplément
      </va-button>
    </div>
  </div>
  <div class="row" v-for="supplement in form.supplements" :key="supplement.id">
    <div class="flex md4">
      <va-input v-model="supplement.title" type="text" label="Titre" placeholder="Titre"/>
    </div>
    <div class="flex md4">
      <va-input v-model.number="supplement.price" type="number" label="Prix" placeholder="Prix en DHs"/>
    </div>
    <div class="flex md4">
      <va-input v-model="supplement.description" type="text" label="Description" placeholder="Description"/>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "ProgramCreateDates",
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      form: this.modelValue,
      columns: [
        {
          key: 'date',
          label: 'Date',
        },
        {
          key: 'adultPrice',
          label: 'Prix Adulte (Dhs)',
        },
        {
          key: 'childPrice',
          label: 'Prix Enfant (Dhs)',
        },
        {
          key: 'infantPrice',
          label: 'Prix Bébé (Dhs)',
        },
      ]
    }
  },
  computed: {
    formatDate () {
      return (value) => {
        if (value) {
          return dayjs(value).format("DD/MM/YYYY");
        }
      }
    },
    selectedDates () {
      return this.form.detailedDates.map(date => date.date);
    }
  },
  methods: {
    updateAdultPrice (value, rowIndex) {
      this.form.detailedDates[rowIndex].adultPrice = value ? Number(value) : null;
    },
    updateChildPrice (value, rowIndex) {
      this.form.detailedDates[rowIndex].childPrice = value ? Number(value) : null;
    },
    updateInfantPrice (value, rowIndex) {
      this.form.detailedDates[rowIndex].infantPrice = value ? Number(value) : null;
    },
    addSupplement () {
      this.form.supplements.push({
        id: this.form.supplements.length,
        title: '',
        description: '',
        price: 0
      })
    },
    toggleDate (value) {
      if (value) {
        const valueIdx = this.form.detailedDates.findIndex(date => dayjs(date.date).isSame(dayjs(value), 'day'))
        if (valueIdx === -1) {
          this.form.detailedDates.push({
            date: value,
            adultPrice: this.form.baseAdultPrice,
            childPrice: this.form.baseChildrenPrice,
            infantPrice: this.form.baseInfantPrice
          })
        } else {
          this.form.detailedDates.splice(valueIdx, 1)
        }
      }
    },
  },
}
</script>

<style scoped>

</style>

<template>
  <va-modal v-model="createNewCityModal" hide-default-actions>
    <template #header>
      <h2>Ajouter une nouvelle Ville</h2>
    </template>
    <div class="row mt-4">
      <div class="flex md12">
        <form @submit="createNewCity">
          <va-select
            class="mb-4"
            label="Pays"
            placeholder="Pays"
            :options="countryOptions"
            :track-by="(option) => option.id"
            :text-by="(option) => option.name"
            :value-by="(option) => option"
            v-model="formCreateCity.country"
            searchable
          />
          <va-select
            class="mb-4"
            label="Région"
            placeholder="Région"
            :options="stateOptions"
            :track-by="(option) => option.id"
            :text-by="(option) => option.name"
            :value-by="(option) => option"
            v-model="formCreateCity.state"
            searchable
            :loading="loadingStates"
            :disabled="disableStatesSelect"
          />
          <va-input
            class="mb-4"
            label="Ville"
            placeholder="Ville"
            v-model="formCreateCity.name"
          />
        </form>
      </div>
    </div>
    <template #footer>
      <va-button @click="createNewCity" :loading="loadingCitiesCreation">
        Enregistrer
      </va-button>
    </template>
  </va-modal>
  <div class="row">
    <div class="flex md2">
      <va-button-group>
        <va-button @click="addDay" icon="add" />
        <va-button @click="removeDay(selectedDay.id)" icon="remove" />
      </va-button-group>
    </div>
    <div class="flex m9">
      <va-button @click="duplicate(selectedDay.id)">Dupliquer</va-button>
    </div>
  </div>
  <div class="row">
    <div class="flex md2">
      <va-tabs v-model="tabValue" vertical>
        <template #tabs>
          <va-tab v-for="(day, i) in form.days" :name="day.id" :key="day.id">
            Jour {{ i + 1}}
          </va-tab>
        </template>
      </va-tabs>
    </div>
    <va-divider vertical></va-divider>
    <div class="flex md9">
      <va-input class="mb-4" v-model="selectedDay.title" label="Activité Principale" placeholder="Activité principale"/>
      <va-input
        class="mb-4"
        v-model="selectedDay.description"
        type="textarea"
        label="Programme du jour"
        placeholder="Programme du jour"
        :min-rows="10"
      />
      <va-button @click="addCity" class="mb-2">Ajouter Ville</va-button>
      <div v-for="city in selectedDay.cities" :key="city.id">
        <va-divider></va-divider>
        <div class="row">
          <div class="flex md1 lg1">
            <va-button icon="trash"  @click="deleteCity(city.id)"/>
          </div>
          <div class="flex md2 lg2">
            <va-time-input manual-input v-model="city.time" label="Heure" mask="time" placeholder="HH:mm"/>
          </div>
          <div class="flex md3 lg3">
            <va-select
              class="mb-4"
              label="Ville"
              :options="cityOptions"
              :track-by="(option) => option.id"
              :text-by="customFilter"
              :value-by="(option) => option"
              v-model="city.city"
              placeholder="Ville"
              @update-search="updateCityOptions"
              allowCreate
              @create-new="addNewCityOption($event, city)"
              :loading="loadingCities"
            />
          </div>
          <div class="flex md3 lg3">
            <va-input class="mb-4" v-model="city.location" label="Adresse" placeholder="Adresse"/>
          </div>
          <div class="flex md3 lg3">
            <va-radio
              v-for="(option, index) in options"
              :key="index"
              v-model="city.type"
              :option="option"
              :label="labels[index]"
            />
          </div>
          <va-divider></va-divider>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgramCreateDaily",
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    countryOptions: {
      type: Array,
      required: true
    },
  },
  data () {
    return {
      formCreateCity: {
        name: '',
        country: '',
        state: '',
      },
      cityOptions: [],
      stateOptions: [],
      disableStatesSelect: true,
      form: this.modelValue,
      labels: ['Ville de Départ', 'Ville de Visite', 'Ville de Retour'],
      options: ['departure', 'visit', 'arrival'],
      tabValue: this.modelValue.days[0].id,
      loadingCities: false,
      loadingStates: false,
      loadingCitiesCreation: false,
      createNewCityModal: false,
      cityToUpdate: null,
    }
  },
  computed: {
    selectedDay () {
      return this.form.days.find(day => day.id === this.tabValue)
    }
  },
  methods: {
    customFilter (option, query) {
      return option.name
    },
    addDay () {
      this.form.days.push({
        id: this.form.days.length + 1,
        title: '',
        description: '',
        cities: []
      })
      this.tabValue = this.form.days[this.form.days.length - 1].id
    },
    removeDay (id) {
      this.form.days = this.form.days.filter(day => day.id !== id)
      if (this.tabValue === '1') {
        this.tabValue = this.form.days[0].id
      } else {
        this.tabValue = this.form.days[this.form.days.length - 1].id
      }
    },
    duplicate (id) {
      console.log(`id of day to duplicate: ${id}`)
      const index = this.form.days.findIndex(day => day.id === id)
      const dayToDuplicate = this.form.days[index]
      this.form.days.splice(index + 1, 0, {
        id: this.form.days.length + 1,
        title: dayToDuplicate.title,
        description: dayToDuplicate.description,
        cities: dayToDuplicate.cities
      })
    },
    addCity () {
      let type;
      if (this.selectedDay.id === '1') {
        type = 'departure'
      } else if (this.selectedDay.id === this.form.days.length.toString()) {
        type = 'arrival'
      } else {
        type = 'visit'
      }
      this.selectedDay.cities.push({
        id: this.selectedDay.cities.length + 1,
        time: null,
        city: '',
        location: '',
        type
      })
    },
    async updateCityOptions (search) {
      if (search.length > 2) {
        try {
          this.loadingCities = true
          const response = await this.axios.get(`/api/cities?q=${search}`)
          this.cityOptions = response.data
        } finally {
          this.loadingCities = false
        }
      }
    },
    async createNewCity() {
      this.loadingCitiesCreation = true
      const validForm = {
        'name': this.formCreateCity.name,
        'region_id': this.formCreateCity.state.id,
      }
      try {
        const response = await this.axios.post('/api/cities', validForm)
        this.createNewCityModal = false
        this.formCreateCity = {
          name: '',
          country: '',
          state: '',
        }
        this.cityToUpdate.city = response.data
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingCitiesCreation = false
      }
    },
    addNewCityOption (option, city) {
      if (this.loadingCities === false) {
        this.createNewCityModal = true
        this.formCreateCity.name = option
        this.cityToUpdate = city
      }
    },
    deleteCity (departureId) {
      this.selectedDay.cities = this.selectedDay.cities.filter(city => city.id !== departureId)
    }
  },
  watch: {
    async 'formCreateCity.country' (value) {
      if (value) {
        this.loadingStates = true
        const states = await this.axios.get(`/api/countries/${value.id}/states`)
        this.stateOptions = states.data
        this.loadingStates = false
        this.disableStatesSelect = false
      }
    }
  }
}
</script>

<style scoped>

</style>
